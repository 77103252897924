import { useContext, useEffect } from "react";
import { RecipeList } from "../components/RecipeList";
import { RecipesContext } from "../context/RecipesContext";
import { DefaultLayout } from "../layouts/DefaultLayout";
import { useCurrentUser } from "../context/SessionContext";

export const HomePage = () => {
  const user = useCurrentUser();
  const { recipes, recentRecipeIds, onList } = useContext(RecipesContext);

  useEffect(() => {
    onList({ recipeIds: recentRecipeIds || [] });
  }, [recentRecipeIds]);

  return (
    <DefaultLayout>
      <div className="pb-3">
        {recentRecipeIds.length === 0 && (
          <div className="text-center text-xl py-20">
            {user?.authenticated ? (
              <>No recent recipes...</>
            ) : (
              <>Ask for a follow link from whomever invited you or log in and get started saving recipes!</>
            )}
          </div>
        )}
        {recentRecipeIds.length > 0 && (
          <>
            <h1 className="text-center text-3xl py-3">Recent viewed recipes...</h1>
            <RecipeList recipes={recipes} />
          </>
        )}
      </div>
    </DefaultLayout>
  );
};
