import { DefaultLayout } from "../layouts/DefaultLayout";

export const ErrorPage = (props: { error: Pick<Error, "message">; reset?: () => void; children?: React.ReactNode }) => {
  console.error(props.error);
  return (
    <DefaultLayout>
      <div className="h-screen flex items-center justify-center">
        <div>
          <h1 className="text-xl text-center">Error</h1>
          <div className="my-3 text-error">{props.error.message}</div>
          {props.reset && (
            <div className="pointer" onClick={props.reset}>
              Try Again
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};
