import axios from "axios";

const axiosClient = axios.create();
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const userMessage = error.response?.data?.userMessage;
    if (userMessage) {
      return Promise.reject(new Error(userMessage));
    }
    return Promise.reject(error);
  },
);

export const client = axiosClient;
