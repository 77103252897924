import { useState } from "react";
import { RecipeIngredient } from "./RecipeIngredient";
import { ImageViewer } from "./ImageViewer";
import { Instruction as InstructionType } from "@recipes/lib";

export const RecipeInstruction = (props: { instruction: InstructionType }) => {
  const { instruction } = props;
  const [showImages, toggleImages] = useState(false);
  return (
    <div>
      <div className="whitespace-pre-line">{instruction.detail}</div>
      <div className="flex justify-between pt-1 pl-5">
        <ul className="text-sm font-light">
          {instruction.ingredients?.map((ingredient, idx) => (
            <li key={idx} className="list-disc list-inside">
              <RecipeIngredient ingredient={ingredient} />
            </li>
          ))}
        </ul>
        <div className="flex items-center gap-5">
          {instruction.images
            ?.slice(0, 2)
            ?.map((image, idx) => (
              <img
                width={50}
                height={50}
                alt="Instruction Image"
                key={idx}
                src={image}
                className="h-12 cursor-pointer"
                onClick={() => toggleImages(true)}
              />
            ))}
        </div>
      </div>
      {instruction.images?.length && showImages && (
        <ImageViewer images={instruction.images} onClose={() => toggleImages(false)} />
      )}
    </div>
  );
};
