import { Button } from "../Button";
import { Modal } from "../Modal";
import { useToast } from "../../hooks/useToast";
import { useState } from "react";
import { client } from "../../hooks/client";
import { CreateRecipe } from "@recipes/lib";
import { Spinner } from "../Spinner";
import { Icon } from "../Icon";

export const RecipeTextGenerator = (props: { content: string; onImport: (recipe: CreateRecipe) => void }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    client
      .post<CreateRecipe>("/api/recipe/generate", { content: props.content })
      .then((r) => props.onImport(r.data))
      .catch((e) => toast.onError(e.message))
      .finally(() => setLoading(false));
  };

  if (!props.content) {
    return <></>;
  }

  return (
    <Modal
      renderActions={({ onClose }) => (
        <>
          <Button intent="ghost" className="min-w-24" onClick={onClose}>
            Cancel
          </Button>
          <Button intent="primary" className="min-w-24" onClick={onClick}>
            Generate
          </Button>
        </>
      )}
      renderTrigger={({ onOpen }) => (
        <div className="absolute right-0 bottom-0">
          {loading ? (
            <div
              className="rounded mb-4 mr-2 opacity-50 hover:opacity-100 h-9 w-9 flex items-center justify-center"
              onClick={onOpen}
            >
              <Spinner />
            </div>
          ) : (
            <div
              className="rounded bg-accent mb-4 mr-2 opacity-50 hover:opacity-100 h-9 w-9 flex items-center justify-center"
              onClick={onOpen}
            >
              <Icon.Sparkles className="size-6" />
            </div>
          )}
        </div>
      )}
    >
      Generate recipe from description?
    </Modal>
  );
};
