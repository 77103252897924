import { useCallback, useMemo } from "react";

export const TextArea = (props: {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onPaste?: (value: string) => void;
  className?: string;
  size?: "xs" | "sm" | "lg";
  type?: "text" | "password" | "search";
  placeholder?: string;
}) => {
  const { size, className } = props;
  const classes = useMemo(() => {
    const classes = ["textarea", "textarea-bordered", "bg-base-100", "text-base-content"];
    if (className) {
      classes.push(className);
    }
    if (size === "lg") {
      classes.push("input-lg");
    } else if (size === "sm") {
      classes.push("input-sm");
    } else if (size === "xs") {
      classes.push("input-xs");
    }
    return classes.join(" ");
  }, [size, className]);

  const { onPaste } = props;
  const onPasteCallback = useCallback(
    (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
      if (onPaste) {
        e.preventDefault();
        onPaste(e.clipboardData.getData("text"));
      }
    },
    [onPaste],
  );

  return (
    <textarea
      value={props.value}
      className={classes}
      placeholder={props.placeholder}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={(e) => props.onChange(e.target.value)}
      onPasteCapture={onPasteCallback}
    />
  );
};
