import Footer from "../components/Footer";
import { Header } from "../components/Header";
import { ToastContainer } from "../components/ToastContainer";

export const DefaultLayout = (props: React.PropsWithChildren<{ footerChildren?: React.ReactNode }>) => {
  return (
    <div>
      <Header />
      <div className="container mx-auto mb-16">{props.children}</div>
      <Footer children={props.footerChildren} />
      <ToastContainer />
    </div>
  );
};
