import { Ingredient } from "@recipes/lib";

export const RecipeIngredient = (props: { ingredient: Ingredient }) => {
  return <span>{formatIngredient(props.ingredient)}</span>;
};

export const formatIngredient = (ingredient: Ingredient) => {
  const amount = ingredient.amount;
  if (!amount) {
    return ingredient.name;
  } else if (!amount.unit) {
    return `${amount.value} ${ingredient.name}`;
  }
  return `${amount.value} ${amount.unit} ${ingredient.name}`;
};
