import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RecipesContext } from "../context/RecipesContext";
import { Icon } from "./Icon";
import { NavMenu } from "./NavMenu";
import { TextInput } from "./TextInput";
import { UserAvatar } from "./UserAvatar";

export const Header = () => {
  const [isOpen, toggleOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const { onList } = useContext(RecipesContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onSearch = useCallback(() => {
    toggleOpen(false);
    if (filter) {
      onList({ filter });
    } else {
      onList({});
    }
    navigate("/");
  }, [navigate, filter]);

  useEffect(() => {
    toggleOpen(false);
  }, [pathname]);

  return (
    <>
      <div className={"navbar flex gap-4 bg-neutral text-neutral-content"}>
        <div className="flex-none">
          <Link to="/">
            <img alt="Logo" src={"/logo.svg"} className="h-12" />
          </Link>
        </div>
        <div className="flex-1 justify-end flex gap-5">
          <TextInput
            type="search"
            className="w-full md:w-96"
            value={filter}
            onChange={setFilter}
            onEnterPressed={onSearch}
            placeholder="Search for recipes"
          />
        </div>
        {!isOpen && <UserAvatar onClick={() => toggleOpen(true)} />}
        {isOpen && (
          <div className="btn btn-sm btn-circle opacity-50" onClick={() => toggleOpen(false)}>
            <Icon.Close />
          </div>
        )}
      </div>
      {isOpen && <NavMenu />}
    </>
  );
};
