import { useCallback } from "react";
import { useFollowerContext } from "../context/FollowersContext";
import { useCurrentUser } from "../context/SessionContext";
import { Button, ButtonProps } from "./Button";
import { Icon } from "./Icon";
import { client } from "../hooks/client";
import { useToast } from "../hooks/useToast";
import { Authenticated } from "./Authenticated";
import { Guest } from "./Guest";

export const FollowButton = (props: { authorId: string } & Partial<ButtonProps>) => {
  const { authorId, ...buttonProps } = props;
  const toast = useToast();
  const { following, onReload } = useFollowerContext();
  const user = useCurrentUser();

  const onFollowCallback = useCallback(async () => {
    try {
      await client.post("/api/user/follow", { id: authorId });
      toast.onInfo(`Followed!`);
      onReload();
    } catch (e: any) {
      toast.onError(`Failed to follow user: ${e?.message}`);
    }
  }, [authorId]);

  if (!authorId) {
    return <></>;
  }

  if (user?.id === authorId) {
    return <></>;
  }

  if (following.find((f) => f.id === authorId)) {
    return <></>;
  }

  return (
    <>
      <Authenticated>
        <Button intent="accent" className="mt-3 mr-3" {...buttonProps} onClick={onFollowCallback}>
          <Icon.Plus style={{ height: 14 }} />
          Follow
        </Button>
      </Authenticated>
      <Guest>
        <Button size="lg" intent="primary" nativeHref="/api/auth/google/login" className="my-6">
          <Icon.User className="size-8" />
          <div className="flex-1 text-left">Log In to Follow</div>
        </Button>
      </Guest>
    </>
  );
};
