import { CreateRecipe } from "@recipes/lib";
import { ReactNode, useMemo, useRef } from "react";
import { client } from "../hooks/client";
import { useToast } from "../hooks/useToast";

type ImageUploadProps = {
  label?: ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  fill?: boolean;
  size?: "xs" | "sm" | "lg";
  intent?: "info" | "success" | "warning" | "error" | "neutral" | "primary" | "secondary" | "accent" | "ghost" | "link";
  extract?: boolean;
  className?: string;
  onImageChanged?: (image: FileList | null) => void;
  onUpload: (result: Array<{ imageUrl: string; recipe?: CreateRecipe }>) => void;
};

export const ImageUpload = (props: ImageUploadProps) => {
  const { onError } = useToast();

  const ref = useRef<HTMLInputElement>(null);

  const className = useMemo(() => {
    const classNames: string[] = ["file-input", "file-input-bordered"];
    if (props.className) {
      classNames.push(props.className);
    }
    if (props.intent) {
      classNames.push(`file-input-${props.intent}`);
    }
    if (props.size) {
      classNames.push(`file-input-${props.size}`);
    }
    if (props.fill) {
      classNames.push("w-full");
    }
    return classNames.join(" ");
  }, [props.intent, props.fill, props.size, props.className]);

  return (
    <label className="flex flex-col">
      {props.label && <div>{props.label}</div>}
      <input
        ref={ref}
        type="file"
        accept="image/*,.heic,.heif"
        className={className}
        disabled={props.disabled}
        multiple={props.multiple}
        onChange={async (event) => {
          const files = event.target.files ?? [];
          props.onImageChanged?.(event.target.files);
          const imageUrls: Array<{ imageUrl: string; recipe?: CreateRecipe }> = [];
          for (const file of files) {
            if (file) {
              const formData = new FormData();
              formData.append("name", file.name);
              formData.append("file", file);
              try {
                const response = await client.post<{
                  imageUrl: string;
                  recipe?: CreateRecipe;
                }>("/api/image/upload", formData, {
                  params: { extract: true },
                  headers: { "Content-Type": "multipart/form-data" },
                });
                if (response.data.imageUrl) {
                  imageUrls.push(response.data);
                }
                if (ref.current) {
                  ref.current.value = "";
                }
              } catch (e: any) {
                onError(e?.message);
              }
            }
          }
          props.onUpload(imageUrls);
          if (ref.current) {
            ref.current.value = "";
          }
        }}
      />
    </label>
  );
};
