import { Ingredient, UNIT_REGEX } from "@recipes/lib";
import BigNumber from "bignumber.js";

const INGREDIENT_REGEXP = new RegExp(`(?<amount>\\d+)\\s+(?<unit>${UNIT_REGEX}\\s+)?(?<name>.+)`, "i");

export const parseIngredient = (line: string): Ingredient | undefined => {
  const matcher = line.match(INGREDIENT_REGEXP);
  if (matcher) {
    const value = new BigNumber(matcher.groups?.amount ?? "");
    return {
      name: (matcher.groups?.name ?? "").trim(),
      amount: value.isNaN()
        ? undefined
        : {
            value: value.toNumber(),
            unit: matcher.groups?.unit?.trim(),
          },
    };
  }
};

export const parseIngredients = (lines: string[]) => {
  const ingredients: Ingredient[] = [];

  for (const line of lines) {
    const ingredient = parseIngredient(line);
    if (ingredient) {
      ingredients.push(ingredient);
    }
  }

  return ingredients;
};
