import { Recipe } from "@recipes/lib";
import { useCallback } from "react";
import { useRecipe } from "./useRecipe";
import { client } from "./client";

export const useEditRecipe = (id: string, defaultRecipe?: Recipe) => {
  const { recipe, error, loading, onChange, onReload } = useRecipe(id, defaultRecipe);

  const saveRecipe = useCallback(async (recipe: Recipe) => {
    return await client.put<Recipe>(`/api/recipe/${recipe.id}`, recipe).then((value) => value.data);
  }, []);

  const archiveRecipe = useCallback(async (recipe: Recipe) => {
    return await client.delete<Recipe>(`/api/recipe/${recipe.id}`);
  }, []);

  return {
    recipe,
    loading,
    error,
    onChange,
    onArchive: async (recipe: Recipe) => {
      await archiveRecipe(recipe);
    },
    onSave: async (recipe: Recipe) => {
      await saveRecipe(recipe);
      onReload();
    },
  };
};
