import { createContext, useContext, useEffect } from "react";
import { useFollowers } from "../hooks/useFollowers";
import { useFollowing } from "../hooks/useFollowing";
import { useSession } from "./SessionContext";

type FollowersContext = {
  followers: Array<{ id: string; handle: string }>;
  following: Array<{ id: string; handle: string }>;
  onReload: () => void;
};

const FollowersContext = createContext<FollowersContext>({
  followers: [],
  following: [],
  onReload: () => {},
});

export const FollowersProvider = (props: React.PropsWithChildren<unknown>) => {
  const { user } = useSession();
  const followers = useFollowers();
  const following = useFollowing();

  const onReload = () => {
    followers.onLoad();
    following.onLoad();
  };

  useEffect(() => {
    if (user.authenticated) {
      onReload();
    }
  }, [user.authenticated]);

  const value = {
    followers: followers.users,
    following: following.users,
    onReload,
  };

  return <FollowersContext.Provider value={value}>{props.children}</FollowersContext.Provider>;
};

export const useFollowerContext = () => {
  return useContext(FollowersContext);
};
