import { DefaultLayout } from "../../layouts/DefaultLayout";

export const ColorsPage = () => {
  return (
    <DefaultLayout>
      <div className="text-left">
        <h1 className="font-mono font-bold text-2xl my-2">Colors</h1>
        <div className="flex gap-4 flex-col">
          {colors.map((color, idx) => (
            <div className="inline-flex items-start gap-4 bg-white rounded" key={idx}>
              <div className={"badge relative top-2 m-3 p-3 " + color.className} />
              <div className="flex-1 my-3">
                <div className="font-mono font-bold">{color.name}</div>
                <div className="text-xs opacity-60">{color.detail}</div>
                <div className="flex">
                  {color.name.endsWith("content") && (
                    <div>
                      <div className={`btn bg-${color.name.replace("-content", "")} text-${color.name}`}>Example</div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`flex-1 p-3 bg-${color.name.replace(/-content/, "")} text-${color.name.replace(
                  /-content|-focus/,
                  "",
                )}-content`}
              >
                No one shall be subjected to arbitrary arrest, detention or exile. Everyone is entitled in full equality
                to a fair and public hearing by an independent and impartial tribunal, in the determination of his
                rights and obligations and of any criminal charge against him.
              </div>
            </div>
          ))}
        </div>
      </div>
    </DefaultLayout>
  );
};

const colors = [
  {
    name: "primary",
    detail: "Primary color",
    className: "bg-primary",
  },
  {
    name: "primary-focus",
    detail: "Primary color when focused",
    className: "bg-primary-focus",
  },
  {
    name: "primary-content",
    detail: "Foreground content color to use on primary color",
    className: "bg-primary-content",
  },
  {
    name: "secondary",
    detail: "Secondary color",
    className: "bg-secondary",
  },

  {
    name: "secondary-focus",
    detail: "Secondary color when focused",
    className: "bg-secondary-focus",
  },
  {
    name: "secondary-content",
    detail: "Foreground content color to use on secondary color",
    className: "bg-secondary-content",
  },
  {
    name: "accent",
    detail: "Accent color",
    className: "bg-accent",
  },
  {
    name: "accent-focus",
    detail: "Accent color when focused	",
    className: "bg-accent-focus",
  },
  {
    name: "accent-content",
    detail: "Foreground content color to use on accent color",
    className: "bg-accent-content",
  },
  {
    name: "neutral",
    detail: "Neutral color",
    className: "bg-neutral",
  },
  {
    name: "neutral-focus",
    detail: "Neutral color when focused	",
    className: "bg-neutral-focus",
  },

  {
    name: "neutral-content",
    detail: "Foreground content color to use on neutral color",
    className: "bg-neutral-content",
  },
  {
    name: "base-100",
    detail: "Base color",
    className: "bg-base-100",
  },
  {
    name: "base-200",
    detail: "Base color, a little darker",
    className: "bg-base-200",
  },
  {
    name: "base-300",
    detail: "Base color, even more darker",
    className: "bg-base-300",
  },
  {
    name: "base-content",
    detail: "Foreground content color to use on base color",
    className: "bg-base-content",
  },
  {
    name: "info",
    detail: "Info color	",
    className: "bg-info",
  },
  {
    name: "info-content",
    detail: "Foreground content color to use on info color",
    className: "bg-info-content",
  },
  {
    name: "success",
    detail: "Success color",
    className: "bg-success",
  },
  {
    name: "success-content",
    detail: "Foreground content color to use on success color",
    className: "bg-success-content",
  },
  {
    name: "warning",
    detail: "Warning color",
    className: "bg-warning",
  },
  {
    name: "warning-content",
    detail: "Foreground content color to use on warning color",
    className: "bg-warning-content",
  },
  {
    name: "error",
    detail: "Error color",
    className: "bg-error",
  },
  {
    name: "error-content",
    detail: "Foreground content color to use on error color",
    className: "bg-error-content",
  },
];
