export const ImageViewer = (props: { images: string[]; onClose: () => void }) => {
  return (
    <div className="modal modal-open overflow-auto py-12" onClick={props.onClose}>
      <div>
        <div className="bg-white gap-4 p-4 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
          {props.images.map((image, idx) => (
            <div key={idx} className="">
              <img src={image} alt="Recipe Image" width={150} height={150} className="w-full" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
