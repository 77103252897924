import { TextArea } from "../TextArea";
import { Instruction } from "@recipes/lib";

export const InstructionEditor = (props: {
  instruction: Instruction;
  onChange: (instruction: Instruction) => void;
}) => {
  const instruction = props.instruction;
  return (
    <div>
      <TextArea
        size="sm"
        className="w-full h-48"
        placeholder={`Step details...`}
        value={instruction.detail}
        onChange={(value) => props.onChange({ ...instruction, detail: value })}
      />
    </div>
  );
};
