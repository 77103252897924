import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { DateTime } from "../../components/DateTime";
import { Icon } from "../../components/Icon";
import { RecipeIngredient } from "../../components/RecipeIngredient";
import { RecipeInstruction } from "../../components/RecipeInstruction";
import { useCurrentUser } from "../../context/SessionContext";
import { useFavoriteRecipe } from "../../hooks/useFavoriteRecipe";
import { useRecipe } from "../../hooks/useRecipe";
import { DefaultLayout } from "../../layouts/DefaultLayout";
import { ErrorPage } from "../ErrorPage";
import { NotFoundPage } from "../NotFoundPage";
import { RecipesContext } from "../../context/RecipesContext";

export const RecipeDetailPage = () => {
  const params = useParams<{ recipeId: string }>();
  const loc = useLocation();
  const user = useCurrentUser();
  const { logRecentRecipe } = useContext(RecipesContext);
  const { recipe, loading, error } = useRecipe(params.recipeId ?? "", loc.state?.recipe);
  const { onToggle, saved } = useFavoriteRecipe(recipe);
  const [selectedImage, setSelectedImage] = useState(recipe?.headerImage);

  useEffect(() => {
    if (recipe) {
      setSelectedImage(recipe.headerImage);
      logRecentRecipe(recipe);
    }
  }, [recipe]);

  if (error?.type === "axios" && error.error.response?.status === 404) {
    return <NotFoundPage>Recipe not found</NotFoundPage>;
  } else if (error) {
    return <ErrorPage error={error.error} />;
  }

  if (!recipe || loading) {
    return <div>Loading</div>;
  }

  return (
    <DefaultLayout>
      <div className="text-left p-3 md:max-w-3xl md:m-auto">
        <h1 className="font-semibold text-3xl flex items-center gap-3 max-w-3xl m-auto mb-4">
          <div className="flex-1 text-left font-normal font-serif overflow-hidden">
            <div className="line-clamp-2">{recipe.title}</div>
          </div>
          <div className="flex-none flex items-center">
            {user?.id === recipe.author.id ? (
              <Link
                to={`/recipe/edit/${recipe.id}`}
                state={{ recipe }}
                className="flex-none mx-2 opacity-25 hover:opacity-100 cursor-pointer"
              >
                <Icon.Pencil className="text-neutral" />
              </Link>
            ) : (
              <>
                {saved === true && (
                  <Icon.StarSolid onClick={() => onToggle(false)} className="size-8 text-primary cursor-pointer" />
                )}
                {saved === false && (
                  <Icon.StarOutline onClick={() => onToggle(true)} className="size-8 text-primary cursor-pointer" />
                )}
              </>
            )}
          </div>
        </h1>
        <div>
          <img
            src={selectedImage}
            alt="Recipe image"
            className="m-auto w-full max-h-[720px]"
            style={{
              objectFit: "fill",
              objectPosition: "center",
              // height: "100px",
            }}
          />
        </div>
        <div className="flex justify-between mb-6 mt-1 text-sm max-w-3xl m-auto">
          <Link className="underline" to={`/author/${recipe.author.id}`}>
            By {recipe.author.name}
          </Link>
          <span className="opacity-50 font-extralight italic text-xs">
            {recipe.createdAt === recipe.modifiedAt ? (
              <>
                Created <DateTime>{recipe.createdAt}</DateTime>
              </>
            ) : (
              <>
                Updated <DateTime>{recipe.modifiedAt}</DateTime>
              </>
            )}
          </span>
        </div>
        {recipe.additionalImages.length > 0 && (
          <div className="flex items-center gap-3 flex-wrap">
            {recipe.additionalImages.map((image, idx) => (
              <div key={idx} className="group relative cursor-pointer" onClick={() => setSelectedImage(image)}>
                <img src={image} className="w-16 h-16" />
              </div>
            ))}
            <div className="group relative cursor-pointer" onClick={() => setSelectedImage(recipe.headerImage)}>
              <img src={recipe.headerImage} className="w-16 h-16" />
            </div>
          </div>
        )}
        {recipe.description.trim() && (
          <>
            <div>{recipe.description}</div>
            <div className="divider" />
          </>
        )}
        <div>
          <div className="border-t-4 border-black"></div>
          <h2 className="font-bold font-serif text-2xl mt-6 mb-3">Ingredients</h2>
          <ul className="ml-3">
            {recipe.ingredients.map((ingredient, idx) => (
              <li key={idx} className="list-disc list-inside">
                <RecipeIngredient ingredient={ingredient} />
              </li>
            ))}
          </ul>
        </div>
        <div className="border-black border-t-4 my-6" />
        <div>
          <h2 className="font-bold font-serif text-2xl mt-6">Directions</h2>

          {recipe.instructions.map((instruction, idx) => (
            <div key={idx} className="mb-6">
              <h3 className="font-bold mt-3">Step {idx + 1}</h3>
              <RecipeInstruction instruction={instruction} />
            </div>
          ))}
        </div>
      </div>
    </DefaultLayout>
  );
};
