import { ReactNode, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

export type ButtonProps = {
  href?: string;
  nativeHref?: string;
  disabled?: boolean;
  fill?: boolean;
  outline?: boolean;
  size?: "xs" | "sm" | "lg";
  intent?: "info" | "success" | "warning" | "error" | "neutral" | "primary" | "secondary" | "accent" | "ghost" | "link";
  className?: string;
  onClick?: () => void;
  children: ReactNode;
};

const IntentMap: { [key in NonNullable<ButtonProps["intent"]>]: string } = {
  info: `btn-info text-info-content`,
  success: `btn-success text-success-content`,
  warning: `btn-warning text-warning-content`,
  error: `btn-error text-error-content`,
  neutral: `btn-neutral text-neutral-content`,
  primary: `btn-primary text-primary-content`,
  secondary: `btn-secondary text-secondary-content`,
  accent: `btn-accent text-accent-content`,
  ghost: `btn-ghost text-ghost-content`,
  link: `btn-link text-link-content`,
};

export const Button = (props: ButtonProps) => {
  const nav = useNavigate();
  const className = useMemo(() => {
    const classNames: string[] = ["btn", "mobile:btn-block"];
    if (props.className) {
      classNames.push(props.className);
    }
    if (props.disabled) {
      classNames.push("btn-disabled");
    }
    if (props.outline) {
      classNames.push("btn-outline");
    } else {
      classNames.push("btn-active");
    }
    if (props.intent) {
      classNames.push(IntentMap[props.intent]);
    }
    if (props.size) {
      classNames.push(`btn-${props.size}`);
    }
    if (props.fill) {
      classNames.push("btn-block");
    }
    return classNames.join(" ");
  }, [props.intent, props.outline, props.fill, props.size, props.className, props.disabled]);

  const { nativeHref, href, onClick } = props;
  const onClickCallback = useCallback(() => {
    if (href) {
      nav(href);
    }
    onClick?.();
  }, [href, onClick, nav]);

  if (nativeHref) {
    return (
      <a href={nativeHref}>
        <button className={className} onClick={onClickCallback} disabled={props.disabled}>
          {props.children}
        </button>
      </a>
    );
  }

  if (href) {
    return (
      <Link to={href}>
        <button className={className} onClick={onClickCallback} disabled={props.disabled}>
          {props.children}
        </button>
      </Link>
    );
  }

  return (
    <button className={className} onClick={onClickCallback} disabled={props.disabled}>
      {props.children}
    </button>
  );
};
