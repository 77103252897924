import { DateTime as LuxonDateTime } from "luxon";

export const DateTime = (props: { children: string }) => {
  const date = LuxonDateTime.fromISO(props.children);
  if (date.isValid) {
    return <>{date.toFormat("LLL dd, yyyy")}</>;
  }
  console.error(`Invalid datetime specified: ${props.children}`);
  return <></>;
};
