import { CreateRecipe } from "@recipes/lib";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { ImageUpload } from "../../components/ImageUpload";
import { RecipeEditor } from "../../components/recipe-editor/RecipeEditor";
import { Spinner } from "../../components/Spinner";
import { TextInput } from "../../components/TextInput";
import { client } from "../../hooks/client";
import { useCreateRecipe } from "../../hooks/useCreateRecipe";
import { useToast } from "../../hooks/useToast";
import { DefaultLayout } from "../../layouts/DefaultLayout";
import { Label } from "../../components/Label";

const DEFAULT_RECIPE = {
  title: "",
  description: "",
  headerImage: "",
  additionalImages: [],
  ingredients: [
    {
      name: "",
    },
  ],
  instructions: [
    {
      detail: "",
    },
  ],
};

export const AddRecipePage = () => {
  const createRecipe = useCreateRecipe();
  const { onInfo, onError } = useToast();
  const navigate = useNavigate();

  const [recipe, setRecipe] = useState<CreateRecipe>(DEFAULT_RECIPE);

  const [selectedTab, onSelectTab] = useState<"scratch" | "website" | "image">("scratch");

  const showRecipeEditor = selectedTab === "scratch" || recipe !== DEFAULT_RECIPE;

  return (
    <DefaultLayout
      footerChildren={
        <>
          <div
            className="flex-1 text-center bg-base-200 text-base-content select-none"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </div>

          <div
            className="flex-1 text-center bg-neutral active:opacity-50 select-none"
            onClick={() => {
              createRecipe(recipe)
                .then((result) => {
                  onInfo(`Created recipe`);
                  navigate(`/recipe/${result.data.id}`, { state: { recipe: result.data } });
                })
                .catch((e) => onError(e.message));
            }}
          >
            Save
          </div>
        </>
      }
    >
      <div className="flex flex-col gap-6 max-w-xl m-auto p-3" suppressHydrationWarning>
        <h1 className="text-left font-bold text-2xl">Add a Recipe</h1>

        <div className="join flex w-full">
          <button
            className={`btn join-item flex-1 ${selectedTab === "scratch" ? "btn-active" : ""}`}
            onClick={() => onSelectTab("scratch")}
          >
            From Scratch
          </button>
          <button
            className={`btn join-item flex-1 ${selectedTab === "website" ? "btn-active" : ""}`}
            onClick={() => onSelectTab("website")}
          >
            Import Website
          </button>
          <button
            className={`btn join-item flex-1 ${selectedTab === "image" ? "btn-active" : ""}`}
            onClick={() => onSelectTab("image")}
          >
            Import Image
          </button>
        </div>

        {selectedTab === "image" && (
          <>
            <RecipeExtractor onImport={(value) => setRecipe({ ...recipe, ...value, public: false })} />
            <br />
          </>
        )}

        {selectedTab === "website" && (
          <>
            <RecipeImporter
              onImport={(value) =>
                setRecipe({
                  ...value,
                  public: false,
                })
              }
            />
          </>
        )}

        {showRecipeEditor && <RecipeEditor showDescriptionGenerator recipe={recipe} onChange={setRecipe} />}

        {showRecipeEditor && (
          <div className="text-center mt-16">
            <Button
              intent="primary"
              className="btn-wide"
              disabled={!recipe.title}
              onClick={() => {
                createRecipe(recipe)
                  .then((result) => {
                    onInfo(`Created recipe`);
                    navigate(`/recipe/${result.data.id}`, { state: { recipe: result.data } });
                  })
                  .catch((e) => onError(e.message));
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

const RecipeExtractor = (props: { onImport: (value: Partial<CreateRecipe>) => void }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="bg-neutral rounded p-5">
      <div className="mb-3 text-lg -mx-3">
        <Label>Import a from an image or photo</Label>
      </div>
      <div className="flex items-center justify-between gap-5">
        <ImageUpload
          disabled={loading}
          fill
          extract
          onImageChanged={(files) => setLoading(files ? true : false)}
          onUpload={(result) => {
            const data = result[0];
            props.onImport({
              ...data.recipe,
              headerImage: data?.recipe?.headerImage || data.imageUrl,
            });
            setLoading(false);
          }}
        />
        {loading && <Spinner />}
      </div>
    </div>
  );
};

const RecipeImporter = (props: { onImport: (value: CreateRecipe) => void }) => {
  const toast = useToast();
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    client
      .post<CreateRecipe>("/api/recipe/import", { url })
      .then((r) => props.onImport(r.data))
      .catch((e) => toast.onError(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <div className="bg-neutral rounded p-5">
      <div className="mb-3 text-lg -mx-3">
        <Label>Import from a website url</Label>
      </div>
      <div className="flex items-center gap-3">
        <TextInput placeholder="Enter a URL here" className="flex-1 min-w-0" value={url} onChange={setUrl} />
        <div className="flex-none">
          {!loading && (
            <Button onClick={onClick} intent="primary">
              Import
            </Button>
          )}
          {loading && <Spinner />}
        </div>
      </div>
    </div>
  );
};
