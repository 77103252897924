import { useCallback, useState } from "react";
import { client } from "./client";
import { useToast } from "./useToast";

export const useFollowing = () => {
  const toast = useToast();
  const [users, setUsers] = useState<Array<{ id: string; handle: string }>>([]);
  const [loading, setLoading] = useState(false);

  const onLoad = useCallback(() => {
    client
      .get<{ users: Array<{ id: string; handle: string }> }>("/api/user/following")
      .then((value) => {
        setUsers(value.data.users);
      })
      .catch((e) => toast.onError(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { users, onLoad, loading };
};
