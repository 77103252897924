import { Link, useLocation } from "react-router-dom";
import { Icon } from "./Icon";
import { useCurrentUser } from "../context/SessionContext";
import { Authenticated } from "./Authenticated";

export default function Footer(props: { children?: React.ReactNode }) {
  return (
    <>
      <DesktopFooter />
      <MobileFooter children={props.children} />
    </>
  );
}

const DesktopFooter = () => {
  const user = useCurrentUser();
  return (
    <div className="hidden md:block">
      <hr className="my-5" />
      <footer className="container mx-auto mb-16">
        <Authenticated>
          <div className="text-center">
            <Link to="/colors" className="underline">
              Click here to view the theme
            </Link>
          </div>
        </Authenticated>
      </footer>
    </div>
  );
};

const MobileFooter = (props: { children?: React.ReactNode }) => {
  const { pathname } = useLocation();
  const selectedClass = "border-t-4 border-base-300 bg-primary";

  if (props.children) {
    return (
      <div className="btm-nav bg-neutral text-neutral-content md:hidden flex flex-row items-stretch">
        {props.children}
      </div>
    );
  }

  return (
    <div className="btm-nav bg-neutral text-neutral-content md:hidden">
      <Link to="/" className={pathname === "/" ? selectedClass : ""}>
        <Icon.Home className="size-5" />
      </Link>
      <Authenticated>
        <Link to="/author" className={pathname === "/author" ? selectedClass : ""}>
          <Icon.MyRecipes className="size-5" />
        </Link>
        <Link to="/followers" className={pathname === "/followers" ? selectedClass : ""}>
          <Icon.User className="size-5" />
        </Link>
      </Authenticated>
    </div>
  );
};
