import { UserIdentity } from "@recipes/lib";
import axios from "axios";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { client } from "../hooks/client";

type SessionContext = {
  user: UserIdentity;
  onRefresh: () => void;
};

const SessionContext = createContext<SessionContext>({
  user: { authenticated: false },
  onRefresh: () => {},
});

export const SessionProvider = (props: React.PropsWithChildren<unknown>) => {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<UserIdentity>({ authenticated: false });

  const onRefresh = useCallback(() => {
    client
      .get<UserIdentity>("/api/auth/me")
      .then((r) => {
        setUser(r.data);
        setInitialized(true);
      })
      .catch((e) => {
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          setUser({ authenticated: false });
          setInitialized(true);
        } else {
          throw e;
        }
      });
  }, []);

  useEffect(() => onRefresh(), []);

  const value = useMemo(() => ({ user, onRefresh }), [user]);

  if (!initialized) {
    return <></>;
  }

  return <SessionContext.Provider value={value}>{props.children}</SessionContext.Provider>;
};

export const useSession = () => {
  return useContext(SessionContext);
};

export const useCurrentUser = () => {
  const session = useContext(SessionContext);
  return session.user.authenticated ? session.user : undefined;
};
