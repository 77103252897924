import { Ingredient, notBlank } from "@recipes/lib";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { formatIngredient } from "../RecipeIngredient";
import { TextArea } from "../TextArea";
import { IngredientEditor } from "./IngredientEditor";
import { parseIngredients } from "./parseIngredients";

export const IngredientListEditor = (props: {
  ingredients: Ingredient[];
  onChange: (value: Ingredient[]) => void;
  onAdd: () => void;
}) => {
  const { ingredients, onChange } = props;

  return (
    <>
      <h2 className="font-bold text-2xl mt-6">Ingredients</h2>
      <IngredientsTextEditor ingredients={ingredients} onChange={onChange} />
      <div>
        <ul>
          {ingredients.map((ingredient, idx) => (
            <li key={idx} className="mt-2 px-5 py-5 mb-5 border border-base-100 rounded-lg bg-base-200">
              <IngredientEditor
                ingredient={ingredient}
                onChange={(value) => {
                  props.onChange([...ingredients.slice(0, idx), value, ...ingredients.slice(idx + 1)]);
                }}
                onAdd={props.onAdd}
                onRemove={() => props.onChange([...ingredients.slice(0, idx), ...ingredients.slice(idx + 1)])}
              />
            </li>
          ))}
          <li className="mt-4">
            <Button size="sm" intent="neutral" onClick={props.onAdd}>
              <Icon.Plus className="h-6 w-6" /> Add Ingredient
            </Button>
          </li>
        </ul>
      </div>
    </>
  );
};

const IngredientsTextEditor = (props: { ingredients: Ingredient[]; onChange: (value: Ingredient[]) => void }) => {
  const ingredients = props.ingredients;
  const ingredientText = ingredients.map(formatIngredient).join("\n");

  return (
    <TextArea
      className="h-48"
      placeholder="Enter ingredients here"
      value={ingredientText}
      onPaste={(value) => {
        const lines = value
          .split(/[\n\r]+/)
          .map((l) => l.trim())
          .filter(notBlank);

        const addedIngredients = parseIngredients(lines);

        if (addedIngredients.length > 0) {
          props.onChange([...ingredients.filter((i) => i.name.trim()), ...addedIngredients]);
        }
      }}
      onChange={() => {
        // TODO: parse manually entered text and update the ingredient list
        //value = parseIngredients(value)
      }}
    />
  );
};
