import { Amount, Ingredient } from "@recipes/lib";
import { BigNumber } from "bignumber.js";
import { useEffect, useState } from "react";
import { TextInput } from "../TextInput";
import { Icon } from "../Icon";

export const IngredientEditor = (props: {
  ingredient: Ingredient;
  onChange: (ingredient: Ingredient) => void;
  onAdd: () => void;
  onRemove: () => void;
}) => {
  const ingredient = props.ingredient;

  return (
    <div className="sm:flex items-center gap-4">
      <div className="sm:mb-0 mb-3 sm:mb-none flex items-center gap-4">
        <AmountEditor
          amount={ingredient.amount}
          onChange={(value) => props.onChange({ ...ingredient, amount: value })}
        />
        <div className="sm:hidden block text-warning opacity-60 cursor-pointer" onClick={props.onRemove}>
          <Icon.Trash className="size-6" />
        </div>
      </div>
      <TextInput
        size="sm"
        placeholder="All Purpose Flour"
        className="flex-1 w-full"
        value={ingredient.name}
        onChange={(value) => props.onChange({ ...ingredient, name: value })}
        onEnterPressed={props.onAdd}
      />
      <div
        className="hidden sm:block text-warning opacity-25 hover:opacity-100 cursor-pointer"
        onClick={props.onRemove}
      >
        <Icon.Trash className="size-6" />
      </div>
    </div>
  );
};

const AmountEditor = (props: { amount?: Amount; onChange: (amount: Amount) => void }) => {
  const [value, setValue] = useState(`${props.amount?.value ?? ""}`);

  const newValue = props.amount?.value;
  useEffect(() => {
    setValue(`${newValue ?? ""}`);
  }, [newValue]);

  return (
    <>
      <TextInput
        placeholder="Ex. 1.5"
        className="w-20 flex-none"
        size="sm"
        value={value}
        onChange={setValue}
        onBlur={() => {
          const parsedValue = new BigNumber(value);
          if (parsedValue.isNaN()) {
            props.onChange({
              value: 1,
              unit: props.amount?.unit,
            });
            setValue(`1`);
          } else {
            props.onChange({
              value: parsedValue.toNumber(),
              unit: props.amount?.unit,
            });
          }
        }}
      />
      <select
        value={props.amount?.unit}
        onChange={(e) =>
          props.onChange({
            value: props.amount?.value ?? 1,
            unit: e.target.value as Amount["unit"],
          })
        }
        className={`flex-1 sm:w-28 select select-bordered select-sm ${!props.amount?.unit ? "opacity-50" : ""}`}
      >
        <option value="">{props.amount?.unit ?? "N/A"}</option>
        <option value="bunch">bunch</option>
        <option value="pkg">pkg</option>
        <option value="cloves">cloves</option>
        <option value="ounces">ounces</option>
        <option value="grams">grams</option>
        <option value="kilograms">kilograms</option>
        <option value="cup">cup</option>
        <option value="tsp">tsp</option>
        <option value="tbsp">tbsp</option>
      </select>
    </>
  );
};
