import { Recipe } from "@recipes/lib";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export const useFavoriteRecipe = (recipe?: Recipe) => {
  const [saved, setSaved] = useState<boolean>();

  useEffect(() => {
    setSaved(undefined);
    if (recipe) {
      axios
        .get<boolean>(`/api/recipe/isFavorite`, {
          params: {
            recipeId: recipe?.id,
          },
        })
        .then((result) => {
          setSaved(result.data);
        });
    }
  }, [recipe?.id]);

  const favoriteRecipe = useCallback(
    (toggle: boolean) => {
      axios.post<Recipe>(`/api/recipe/favorite`, { recipeId: recipe?.id, toggle }).then(() => {
        setSaved(toggle);
      });
    },
    [recipe?.id],
  );

  return {
    recipe,
    saved,
    onToggle: (toggle: boolean) => favoriteRecipe(toggle),
  };
};
