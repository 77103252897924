import { useMemo } from "react";

type CheckboxProps = {
  disabled?: boolean;
  // size?: "xs" | "sm" | "lg";
  intent?: "info" | "success" | "warning" | "error" | "neutral" | "primary" | "secondary" | "accent" | "ghost" | "link";
  className?: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
};

const IntentMap: { [key in NonNullable<CheckboxProps["intent"]>]: string } = {
  info: `checkbox-info text-info-content`,
  success: `checkbox-success text-success-content`,
  warning: `checkbox-warning text-warning-content`,
  error: `checkbox-error text-error-content`,
  neutral: `checkbox-neutral text-neutral-content`,
  primary: `checkbox-primary text-primary-content`,
  secondary: `checkbox-secondary text-secondary-content`,
  accent: `checkbox-accent text-accent-content`,
  ghost: `checkbox-ghost text-ghost-content`,
  link: `checkbox-link text-link-content`,
};

export const Checkbox = (props: CheckboxProps) => {
  const className = useMemo(() => {
    const classNames: string[] = ["checkbox"];
    if (props.className) {
      classNames.push(props.className);
    }
    if (props.intent) {
      classNames.push(IntentMap[props.intent]);
    }
    return classNames.join(" ");
  }, [props.intent, props.className]);

  return (
    <input
      type="checkbox"
      disabled={props.disabled}
      className={className}
      checked={props.checked ?? false}
      onChange={(e) => props.onChange(e.target.checked)}
    />
  );
};
