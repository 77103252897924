import {
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiDocumentPlus,
  HiDocumentText,
  HiExclamationCircle,
  HiExclamationTriangle,
  HiHome,
  HiInformationCircle,
  HiOutlineStar,
  HiPencil,
  HiPlus,
  HiSparkles,
  HiStar,
  HiTrash,
  HiUser,
  HiXMark,
} from "react-icons/hi2";
import { PiListDashesBold } from "react-icons/pi";
import { BiSolidGridAlt } from "react-icons/bi";
import { FaUserFriends } from "react-icons/fa";
import { LuEye, LuLogIn, LuLogOut, LuShare } from "react-icons/lu";
import { RiBookmarkFill, RiBookmarkLine } from "react-icons/ri";

// https://react-icons.github.io/react-icons/
// redefine the icons we actually use here...
export const Icon = {
  Bookmark: RiBookmarkFill,
  BookmarkOutline: RiBookmarkLine,
  DocumentPlus: HiDocumentPlus,
  ChevronDown: HiChevronDown,
  ChevronLeft: HiChevronLeft,
  ChevronRight: HiChevronRight,
  ChevronUp: HiChevronUp,
  Close: HiXMark,
  Friends: FaUserFriends,
  Error: HiExclamationCircle,
  Grid: BiSolidGridAlt,
  List: PiListDashesBold,
  Logout: LuLogOut,
  Login: LuLogIn,
  Home: HiHome,
  Info: HiInformationCircle,
  MyRecipes: HiDocumentText,
  Pencil: HiPencil,
  Plus: HiPlus,
  Share: LuShare,
  Sparkles: HiSparkles,
  StarOutline: HiOutlineStar,
  StarSolid: HiStar,
  Trash: HiTrash,
  User: HiUser,
  Warning: HiExclamationTriangle,
  Eye: LuEye,
};
