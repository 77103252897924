import { Recipe } from "@recipes/lib";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";

export const useRecipe = (id: string, defaultRecipe?: Recipe) => {
  const [recipe, setRecipe] = useState<Recipe | undefined>(defaultRecipe);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ type: "error"; error: Error } | { type: "axios"; error: AxiosError }>();

  const loadRecipe = useCallback((recipeId: string) => {
    setError(undefined);
    setLoading(true);
    axios
      .get<Recipe>(`/api/recipe/${recipeId}`)
      .then((value) => setRecipe(value.data))
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          setError({ type: "axios", error: e });
        } else {
          setError({ type: "error", error: e });
        }
        setRecipe(undefined);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (id && defaultRecipe?.id === id) {
      setError(undefined);
      setRecipe(defaultRecipe);
    } else if (id) {
      loadRecipe(id);
    }
  }, [id, defaultRecipe, loadRecipe]);

  return {
    recipe,
    loading,
    error,
    onReload: () => loadRecipe(id || recipe?.id || ""),
    onChange: (value: Recipe) => setRecipe(value),
  };
};
