import QRCode from "react-qr-code";
import { Link, Navigate } from "react-router-dom";
import { Icon } from "../components/Icon";
import { useFollowerContext } from "../context/FollowersContext";
import { useCurrentUser, useSession } from "../context/SessionContext";
import { DefaultLayout } from "../layouts/DefaultLayout";

export const MyFollowersPage = () => {
  //const { user } = useSession();
  const user = useCurrentUser();
  const { followers, following } = useFollowerContext();

  const shareLink = `${window.location.origin}/author/${user?.id ?? ""}?handle=${encodeURIComponent(user?.handle ?? "")}`;

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-col items-center justify-center my-5 gap-3">
          <div className="">
            Share this code with friends or send them{" "}
            <a href={shareLink} className="underline" target="_blank">
              this link
            </a>
          </div>
          <div className="flex-none bg-white p-5">
            <QRCode value={shareLink} />
          </div>
        </div>
        <h1 className="text-center text-3xl py-3 mt-5">Following</h1>
        <div className="z-[1] text-left inset-0 px-6 py-3">
          {following.map((user) => (
            <Link
              key={user.id}
              to={`/author/${user.id}`}
              state={user}
              className="flex items-center gap-4 btn no-animation mb-4"
            >
              <Icon.User className="size-10" />
              <div className="flex-1 text-left">{user.handle}</div>
              <Icon.ChevronRight className="size-10" />
            </Link>
          ))}
        </div>
        <hr className="my-16" />
        <h1 className="text-center text-3xl py-3">My Followers</h1>
        <div className="z-[1] text-left inset-0 px-6 py-3">
          {followers.map((user) => (
            <Link
              key={user.id}
              to={`/author/${user.id}`}
              state={user}
              className="flex items-center gap-4 btn no-animation mb-4"
            >
              <Icon.User className="size-10" />
              <div className="flex-1 text-left">{user.handle}</div>
              <Icon.ChevronRight className="size-10" />
            </Link>
          ))}
        </div>
      </div>
    </DefaultLayout>
  );
};
