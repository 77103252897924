import { useCallback, useContext, useState } from "react";
import { TextInput } from "../components/TextInput";
import { useCurrentUser, useSession } from "../context/SessionContext";
import { DefaultLayout } from "../layouts/DefaultLayout";
import { Button } from "../components/Button";
import { client } from "../hooks/client";
import { useToast } from "../hooks/useToast";

export const MyProfilePage = () => {
  const { onRefresh } = useSession();
  const user = useCurrentUser();
  const toast = useToast();
  const [handle, setHandle] = useState(user?.handle ?? "");

  const onSaveCallback = useCallback(async () => {
    try {
      await client.post("/api/user/profile", { handle });
      toast.onInfo(`Saved!`);
      onRefresh();
    } catch (e: any) {
      toast.onError(`Failed to save: ${e?.message}`);
    }
  }, [handle]);

  return (
    <DefaultLayout>
      <div className="pt-2 max-w-96 container mx-auto">
        <div>
          <h2 className="font-bold text-2xl mt-6 mb-2">Handle</h2>
          <TextInput className="w-full" value={handle} onChange={setHandle} />
        </div>
        <div>
          <h2 className="font-bold text-2xl mt-6 mb-2">Email</h2>
          <TextInput className="w-full" disabled value={user?.email ?? ""} onChange={() => {}} />
        </div>
        <div className="mt-8 mb-2">
          <Button fill intent="primary" onClick={onSaveCallback}>
            Save
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
};
