import { Recipe } from "@recipes/lib";
import { Link } from "react-router-dom";
import { useCurrentUser } from "../context/SessionContext";
import { Icon } from "./Icon";

export const RecipeCard = (props: { recipe: Recipe }) => {
  const user = useCurrentUser();
  const { recipe } = props;
  return (
    <Link className="block hover:shadow-xl shadow bg-base-200" to={`/recipe/${recipe.id}`} state={{ recipe }}>
      <div>
        <div className="relative">
          <img
            alt="Recipe Header Image"
            src={recipe.headerImage || "/logo.svg"}
            className="aspect-[4/3]"
            style={{
              objectFit: "cover",
            }}
            loading="lazy"
          />
          {recipe.author.id !== user?.id && (
            <div className="px-2 py-1 mb-1 mr-1 absolute right-0 bottom-0 bg-primary text-white rounded-lg text-xs italic font-light">
              by {recipe.author.name}
            </div>
          )}
          {recipe.author.id === user?.id && recipe.public && (
            <div className="opacity-50 px-1 py-1 mb-1 mr-1 absolute right-0 bottom-0 bg-accent text-white rounded-lg text-xs italic font-light">
              <Icon.Eye className="size-4" />
            </div>
          )}
        </div>
        <div className="p-3 overflow-hidden">
          <div className="line-clamp-2">{recipe.title || "Untitled Recipe"}</div>
        </div>
      </div>
    </Link>
  );
};
