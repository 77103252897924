import { Button } from "../Button";
import { Icon } from "../Icon";
import { Instruction } from "@recipes/lib";
import { InstructionEditor } from "./InstructionEditor";

export const InstructionListEditor = (props: {
  instructions: Instruction[];
  onChange: (value: Instruction[]) => void;
}) => {
  const instructions = props.instructions;
  return (
    <div>
      <h2 className="font-bold text-2xl mt-6">Directions</h2>
      {instructions.map((instruction, idx) => (
        <div key={idx} className="mt-3 mb-4">
          <Button
            className="mb-6 opacity-50"
            size="sm"
            intent="neutral"
            onClick={() => {
              props.onChange([...instructions.slice(0, idx), { detail: "" }, ...instructions.slice(idx)]);
            }}
          >
            <Icon.Plus className="size-6" /> Add Step
          </Button>

          <div className="flex items-center gap-2 mb-2">
            <h3 className="font-bold">Step {idx + 1}</h3>
            <div
              className="text-sm text-warning opacity-25 hover:opacity-100 cursor-pointer"
              onClick={() => props.onChange([...instructions.slice(0, idx), ...instructions.slice(idx + 1)])}
            >
              <Icon.Trash className="size-5" />
            </div>
          </div>
          <InstructionEditor
            instruction={instruction}
            onChange={(value) => props.onChange([...instructions.slice(0, idx), value, ...instructions.slice(idx + 1)])}
          />
        </div>
      ))}
      <div className="">
        <Button
          size="sm"
          intent="neutral"
          onClick={() =>
            props.onChange([
              ...instructions,
              {
                detail: "",
              },
            ])
          }
        >
          <Icon.Plus className="size-6" /> Add Step
        </Button>
      </div>
    </div>
  );
};
