import { Recipe } from "@recipes/lib";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { RecipeEditor } from "../../components/recipe-editor/RecipeEditor";
import { client } from "../../hooks/client";
import { useEditRecipe } from "../../hooks/useEditRecipe";
import { useToast } from "../../hooks/useToast";
import { DefaultLayout } from "../../layouts/DefaultLayout";

export default function EditRecipePage() {
  const loc = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ recipeId: string }>();
  const { recipe, loading, onChange, onSave } = useEditRecipe(params.recipeId ?? "", loc.state?.recipe);
  const { onInfo, onError } = useToast();

  if (!recipe || loading) {
    return <div>Loading</div>;
  }

  const onSaveClick = () => {
    onSave(recipe)
      .then(() => {
        navigate(`/recipe/${recipe.id}`);
      })
      .catch((e) => onError(e.message));
  };

  return (
    <DefaultLayout
      footerChildren={
        <>
          <div
            className="flex-1 text-center bg-base-200 text-base-content select-none"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </div>

          <div className="flex-1 text-center bg-neutral active:opacity-50 select-none" onClick={onSaveClick}>
            Save
          </div>
        </>
      }
    >
      <div className="flex flex-col gap-6 max-w-xl m-auto p-3" suppressHydrationWarning>
        <h1 className="text-left font-bold text-2xl">Edit Recipe</h1>
        <RecipeEditor
          recipe={recipe}
          onChange={(value) =>
            onChange({
              ...recipe,
              ...value,
            })
          }
        />

        <div className="text-center mt-16 hidden md:flex md:flex-row flex-col justify-between gap-6">
          <Button intent="primary" className="min-w-32" onClick={onSaveClick}>
            Save
          </Button>

          <DeleteRecipeButton recipe={recipe}>
            {({ onClick }) => (
              <Button intent="error" className="opacity-50 hover:opacity-100 min-w-32" onClick={onClick}>
                Delete
              </Button>
            )}
          </DeleteRecipeButton>
        </div>

        <div className="md:hidden">
          <DeleteRecipeButton recipe={recipe}>
            {({ onClick }) => (
              <Button fill intent="error" className="opacity-50 hover:opacity-100 min-w-32" onClick={onClick}>
                Delete
              </Button>
            )}
          </DeleteRecipeButton>
        </div>

        <OriginalRecipeLocation recipe={recipe} />
        {recipe.metadata?.original_url && (
          <>
            <br />
          </>
        )}
      </div>
    </DefaultLayout>
  );
}

const OriginalRecipeLocation = (props: { recipe: Recipe }) => {
  let originalUrl = props.recipe.metadata?.original_url;
  if (originalUrl) {
    try {
      originalUrl = originalUrl.match(/^https?:\/\//) ? originalUrl : "https://";
      const host = new URL(originalUrl).host;
      <div>
        Recipe originally imported from <a href={originalUrl}>{host}</a>
      </div>;
    } catch (e) {
      console.error(`Failed to parse url ${originalUrl}`);
    }
  }
  return <></>;
};

const DeleteRecipeButton = (props: {
  recipe: Recipe;
  children: (props: { onClick: () => void }) => React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { onInfo, onError } = useToast();

  return (
    <Modal
      renderActions={({ onClose }) => (
        <>
          <Button intent="ghost" className="min-w-24" onClick={onClose}>
            Cancel
          </Button>
          <Button
            intent="error"
            className="min-w-24"
            onClick={() => {
              client
                .delete<Recipe>(`/api/recipe/${props.recipe.id}`)
                .then(() => {
                  onInfo("Deleted recipe");
                  navigate("/author");
                })
                .catch((e) => onError(e.message));
            }}
          >
            Deleted
          </Button>
        </>
      )}
      renderTrigger={({ onOpen }) => props.children({ onClick: onOpen })}
    >
      <div>Are you sure you want to delete the recipe?</div>
    </Modal>
  );
};
