import { Recipe } from "@recipes/lib";
import { useCallback, useState } from "react";
import { useSession } from "../context/SessionContext";
import { client } from "./client";
import { useToast } from "./useToast";

export const useRecipeList = () => {
  const toast = useToast();
  const session = useSession();
  const [total, setTotal] = useState<number>();
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [loading, setLoading] = useState(false);

  const onList = useCallback((input: { filter?: string; authorId?: string; recipeIds?: string[] }) => {
    if (!session.user.authenticated) {
      return;
    }
    client
      .get<{ total: number; recipes: Recipe[] }>("/api/recipe", {
        params: {
          authorId: input.authorId,
          filter: input.filter,
          recipeIds: input.recipeIds ? JSON.stringify(input.recipeIds) : undefined,
          pageSize: 100,
        },
      })
      .then((value) => {
        setRecipes(value.data.recipes);
        setTotal(value.data.total);
      })
      .catch((e) => toast.onError(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { total, recipes, onList, loading };
};
