import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
import { AuthorRecipePage } from "./pages/AuthorRecipePage";
import { ColorsPage } from "./pages/colors/ColorsPage";
import { HomePage } from "./pages/HomePage";
import { MyFollowersPage } from "./pages/MyFollowersPage";
import { AddRecipePage } from "./pages/recipe/AddRecipePage";
import EditRecipePage from "./pages/recipe/EditRecipePage";
import { RecipeDetailPage } from "./pages/recipe/RecipeDetailPage";
import { MyProfilePage } from "./pages/MyProfilePage";

export const ApplicationRoutes = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/author/:authorId?" element={<AuthorRecipePage />} />
      <Route path="/followers" element={<MyFollowersPage />} />
      <Route path="/profile" element={<MyProfilePage />} />
      <Route path="/recipe/add" element={<AddRecipePage />} />
      <Route path="/recipe/edit/:recipeId?" element={<EditRecipePage />} />
      <Route path="/recipe/:recipeId" element={<RecipeDetailPage />} />
      <Route path="/colors" element={<ColorsPage />} />
    </Routes>
  </BrowserRouter>
);
