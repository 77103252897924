import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "./components/ErrorBoundary.tsx";
import { ToastContainer } from "./components/ToastContainer.tsx";
import { RecipesProvider } from "./context/RecipesContext.tsx";
import { SessionProvider } from "./context/SessionContext.tsx";
import { ToastContextProvider } from "./context/ToastContext.tsx";
import "./index.css";
import { ApplicationRoutes } from "./routes.tsx";
import { FollowersProvider } from "./context/FollowersContext.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <SessionProvider>
    <ToastContextProvider>
      <FollowersProvider>
        <RecipesProvider>
          <ErrorBoundary>
            <ApplicationRoutes />
            <ToastContainer />
          </ErrorBoundary>
        </RecipesProvider>
      </FollowersProvider>
    </ToastContextProvider>
  </SessionProvider>,
);
