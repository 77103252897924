import { Recipe } from "@recipes/lib";
import { RecipeCard } from "./RecipeCard";

export const RecipeList = (props: { recipes: Recipe[] }) => {
  return (
    <div className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 grid-cols-2 gap-4 px-3">
      {props.recipes.map((result, idx) => (
        <RecipeCard key={idx} recipe={result} />
      ))}
    </div>
  );
};
