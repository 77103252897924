import { Recipe } from "@recipes/lib";
import { createContext, useEffect, useMemo, useState } from "react";
import { useRecipeList } from "../hooks/useRecipeList";

export const RecipesContext = createContext<{
  total?: number;
  recipes: Recipe[];
  recentRecipeIds: string[];
  logRecentRecipe: (recipe: Pick<Recipe, "id">) => void;
  onList: (input: { filter?: string; authorId?: string; recipeIds?: string[] }) => void;
}>({
  recipes: [],
  recentRecipeIds: [],
  logRecentRecipe: () => {},
  onList: () => {},
});

export const RecipesProvider = (props: React.PropsWithChildren<unknown>) => {
  const recipeList = useRecipeList();
  const { recipeIds, logRecentRecipe } = useRecentRecipes();
  const value = useMemo(() => {
    return {
      ...recipeList,
      recentRecipeIds: recipeIds,
      logRecentRecipe,
    };
  }, [recipeList, recipeIds, logRecentRecipe]);

  return <RecipesContext.Provider value={value}>{props.children}</RecipesContext.Provider>;
};

const RECENT_RECIPE_KEY = `recent-recipes`;

const useRecentRecipes = () => {
  const [recipeIds, setRecipeIds] = useState<string[]>(() => {
    const value = window.localStorage.getItem(RECENT_RECIPE_KEY);
    if (value) {
      return JSON.parse(value);
    }
    return [];
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(RECENT_RECIPE_KEY, JSON.stringify(recipeIds));
    } catch (e) {
      console.error(`Failed to set session storage`, e);
    }
  }, [recipeIds]);

  const logRecentRecipe = (recipe: Pick<Recipe, "id">) => {
    setRecipeIds([recipe.id, ...recipeIds.filter((r) => r !== recipe.id)].slice(0, 10));
  };

  return { recipeIds, logRecentRecipe };
};
