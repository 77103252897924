import { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useMemo } from "react";

export const TextInput = (props: {
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  onEnterPressed?: (value: string) => void;
  onPaste?: (value: string) => void;
  className?: string;
  size?: "xs" | "sm" | "lg";
  type?: "text" | "password" | "search";
  placeholder?: string;
}) => {
  const { value, size, className, onChange, onBlur, onEnterPressed } = props;
  const classes = useMemo(() => {
    const classes = ["input", "input-bordered", "bg-base-100", "text-base-content"];
    if (className) {
      classes.push(className);
    }
    if (size === "lg") {
      classes.push("input-lg");
    } else if (size === "sm") {
      classes.push("input-sm");
    } else if (size === "xs") {
      classes.push("input-xs");
    }
    return classes.join(" ");
  }, [size, className]);

  const { onPaste } = props;
  const onPasteCallback = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      if (onPaste) {
        e.preventDefault();
        onPaste(e.clipboardData.getData("text"));
      }
    },
    [onPaste],
  );

  const onChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const onKeyDownCallback = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onEnterPressed?.(value);
        e.currentTarget?.blur?.();
      }
    },
    [onEnterPressed, value],
  );

  return (
    <input
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      value={props.value}
      className={classes}
      placeholder={props.placeholder}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={onChangeCallback}
      onKeyDown={onKeyDownCallback}
      onPasteCapture={onPasteCallback}
    />
  );
};
