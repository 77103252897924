import { useToast } from "../hooks/useToast";
import { Toast } from "./Toast";

export const ToastContainer = () => {
  const { toasts, onClose } = useToast();
  return (
    <div className="toast toast-start top-0 sm:top-auto pointer-events-none">
      {toasts.map((toast, idx) => (
        <Toast intent={toast.intent} onClose={() => onClose(toast)} key={idx}>
          {toast.message}
        </Toast>
      ))}
    </div>
  );
};
