import { ReactNode, useEffect, useState } from "react";

type ModalProps = {
  renderTrigger?: (props: { onOpen: () => void }) => ReactNode;
  renderActions?: (props: { onClose: () => void }) => ReactNode;
  children: ReactNode;
};

export const Modal = (props: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener("keydown", listener);
    } else {
      window.removeEventListener("keydown", listener);
    }
    return () => window.removeEventListener("keydown", listener);
  }, [isOpen]);

  return (
    <>
      <dialog className={"modal text-black " + (isOpen ? "modal-open" : "")}>
        <div className="modal-box">
          {props.children}
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}>
            ✕
          </button>
          {props.renderActions && (
            <div className="mt-5 flex justify-end flex-col md:flex-row gap-3">{props.renderActions({ onClose })}</div>
          )}
        </div>
      </dialog>
      {props.renderTrigger?.({ onOpen })}
    </>
  );
};
