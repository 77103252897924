import { Icon } from "./Icon";

type Props = { children: React.ReactNode; onClose: () => void };

export const Toast = (props: Props & { intent?: "info" | "warning" | "error"; onClose: () => void }) => {
  const classNames = ["alert flex items-center gap-2 whitespace-normal pointer-events-auto"];
  if (props.intent === "warning") {
    classNames.push("alert-warning");
  } else if (props.intent === "error") {
    classNames.push("alert-error");
  } else {
    classNames.push("alert-info");
  }
  return (
    <div className={classNames.join(" ")}>
      <span>
        {!props.intent && <Icon.Info className="size-8" />}
        {props.intent === "warning" && <Icon.Warning className="size-8" />}
        {props.intent === "error" && <Icon.Error className="size-8" />}
      </span>
      <span>{props.children}</span>
      <span className="btn btn-circle btn-ghost btn-sm">
        <Icon.Close className="size-8" onClick={props.onClose} />
      </span>
    </div>
  );
};
