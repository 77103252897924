import { useNavigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";

export const Authenticated = (props: React.PropsWithChildren<{ redirect?: boolean }>) => {
  const { user } = useSession();
  const nav = useNavigate();
  if (user.authenticated) {
    return <>{props.children}</>;
  }
  if (props.redirect) {
    nav("/api/auth/google/login");
  }
  return <></>;
};
