import { UpdateRecipe } from "@recipes/lib";
import { Checkbox } from "../Checkbox";
import { ImageUpload } from "../ImageUpload";
import { TextArea } from "../TextArea";
import { TextInput } from "../TextInput";
import { IngredientListEditor } from "./IngredientListEditor";
import { InstructionListEditor } from "./InstructionListEditor";
import { RecipeTextGenerator } from "./RecipeTextGenerator";

export const RecipeEditor = (props: {
  showDescriptionGenerator?: boolean;
  recipe: UpdateRecipe;
  onChange: (value: UpdateRecipe) => void;
}) => {
  const { recipe, onChange } = props;

  return (
    <>
      <TextInput placeholder="Title" value={recipe.title} onChange={(title) => onChange({ ...recipe, title })} />
      <div className="relative">
        <TextArea
          className="h-48 w-full"
          placeholder="Description"
          value={recipe.description}
          onChange={(value) => onChange({ ...recipe, description: value })}
        />
        {props.showDescriptionGenerator && (
          <RecipeTextGenerator content={recipe.description} onImport={(value) => onChange({ ...recipe, ...value })} />
        )}
      </div>
      <div>
        <h3 className="text-left font-bold text-2xl mb-6">Header Image</h3>
        <ImageUpload
          intent="neutral"
          onUpload={(urls) => {
            const oldImage = recipe.headerImage;
            const newImage = urls?.[0]?.imageUrl;
            if (oldImage) {
              onChange({ ...recipe, headerImage: newImage, additionalImages: [...recipe.additionalImages, oldImage] });
            } else {
              onChange({ ...recipe, headerImage: newImage });
            }
          }}
        />
        {recipe.headerImage && (
          <div className="my-6">
            <img src={recipe.headerImage} width={600} height={400} alt="Recipe Image" />
          </div>
        )}
        <ImageUpload
          multiple
          label={<h3 className="text-left font-bold text-xl mt-6 mb-2">Additional Images</h3>}
          intent="neutral"
          onUpload={(images) =>
            onChange({ ...recipe, additionalImages: [...recipe.additionalImages, ...images.map((i) => i.imageUrl)] })
          }
        />
      </div>
      <div className="flex items-center gap-3 flex-wrap">
        {recipe.additionalImages.map((image, idx) => (
          <div
            key={idx}
            className="group relative cursor-pointer"
            onClick={() => {
              onChange({
                ...recipe,
                additionalImages: [...recipe.additionalImages.slice(0, idx), ...recipe.additionalImages.slice(idx + 1)],
              });
            }}
          >
            <div className="bg-error opacity-50 inset-0 absolute hidden group-hover:block"></div>
            <img src={image} className="w-16 h-16" />
          </div>
        ))}
      </div>

      <IngredientListEditor
        ingredients={recipe.ingredients}
        onChange={(ingredients) => {
          onChange({ ...recipe, ingredients });
        }}
        onAdd={() => {
          onChange({ ...recipe, ingredients: [...recipe.ingredients, { amount: { value: 1 }, name: "" }] });
        }}
      />
      <InstructionListEditor
        instructions={recipe.instructions}
        onChange={(instructions) => onChange({ ...recipe, instructions })}
      />
      <br />

      <div>
        <label className="cursor-pointer label flex items-center justify-start gap-3">
          <Checkbox
            intent="primary"
            checked={!recipe.public}
            onChange={(value) => onChange({ ...recipe, public: !value })}
          />
          <span className="label-text">Private? (Only you can view)</span>
        </label>
      </div>
    </>
  );
};
