import { createContext, useCallback, useMemo, useState } from "react";

type Toast = {
  message: string;
  intent: "info" | "warning" | "error";
  timeout: NodeJS.Timeout;
};

export const ToastContext = createContext<{
  toasts: Toast[];
  onClose: (toast: Toast) => void;
  onInfo: (message: string) => void;
  onWarning: (message: string) => void;
  onError: (message: string) => void;
}>({
  toasts: [],
  onClose: () => {},
  onInfo: () => {},
  onWarning: () => {},
  onError: () => {},
});

export const ToastContextProvider = (props: React.PropsWithChildren<unknown>) => {
  const [toasts, setToasts] = useState<Array<Toast>>([]);

  const onToast = useCallback(
    (message: string, intent: Toast["intent"]) => {
      const onRemove = () => setToasts((old) => old.filter((oldToast) => oldToast.message !== message));
      // helps prevent the same message getting spammed to toasts
      const existing = toasts.find((t) => t.message === message);
      if (existing) {
        clearTimeout(existing.timeout);
      }
      setToasts([...toasts, { message, intent, timeout: setTimeout(onRemove, 5000) }]);
    },
    [toasts],
  );

  const onClose = useCallback((toast: Toast) => {
    setToasts((old) => old.filter((o) => o !== toast));
  }, []);

  const value = useMemo(() => {
    return {
      toasts,
      onClose,
      onInfo: (message: string) => onToast(message, "info"),
      onWarning: (message: string) => onToast(message, "warning"),
      onError: (message: string) => onToast(message, "error"),
    };
  }, [toasts, onToast, onClose]);

  return <ToastContext.Provider value={value}>{props.children}</ToastContext.Provider>;
};
